import React, { useEffect, useMemo, useRef } from "react"
import "./prediction-enginev2.scss"
import HeaderV2 from "../headerv2/headerv2"
import Hero from "../../microcomponents/hero/hero"
import ClientLogos from "../../microcomponents/clientlogos/clientlogos"
import TopPredictions from "../../microcomponents/toppredictions/toppredictions"
import ClientResults from "../../microcomponents/clientresults/clientresults"
import LeftRightImageText from "../../microcomponents/leftrightimagetext/leftrightimagetext"
import Button from "../../microcomponents/button/button"
import ReactHtmlParser from "react-html-parser"
import { ArrowIcon, CompareSliderIcon } from "../../microcomponents/icons"
import ReactCompareImage from "react-compare-image"
import Container from "../../microcomponents/container/container"
import HeadSubheadHorizontal from "../../microcomponents/headsubheadhorizontal/headsubheadhorizontal"
import Slider from "react-slick"
import Image from "../../components/image-component/image-new"
import { clusteringImages } from "../../utilities/clusteringimages"

export default function PredictionEngineV2() {
  const topSliderRef = useRef(null)
  const bottomSliderRef = useRef(null)
  const isSSR = typeof document !== "undefined"
  const settings = useMemo(() => {
    return {
      dots: false,
      infinite: true,
      autoplay: false,
      arrows: false,
      slidesToShow: 6,
      centerMode: true,
      centerPadding: "0px",
      responsive: [
        {
          breakpoint: 2000,
          settings: {
            slidesToShow: 4,
            centerPadding: "120px",
          },
        },
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 3,
            centerPadding: "7%",
          },
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 4,
            centerPadding: "8%",
          },
        },
        {
          breakpoint: 991,
          settings: {
            centerMode: true,
            centerPadding: "22%",
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            centerPadding: "21%",
            slidesToShow: 1,
          },
        },
      ],
    }
  }, [])
  const settings2 = useMemo(() => {
    return {
      dots: false,
      infinite: true,
      autoplay: false,
      arrows: false,
      slidesToShow: 3,
      centerMode: true,
      centerPadding: "25%",
      responsive: [
        {
          breakpoint: 2000,
          settings: {
            slidesToShow: 3,
            centerPadding: "17.5%",
          },
        },
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 2,
            centerPadding: "21.95%",
          },
        },
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 3,
            centerPadding: "18%",
          },
        },
        {
          breakpoint: 991,
          settings: {
            centerMode: true,
            centerPadding: "36%",
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            centerPadding: "21%",
            slidesToShow: 1,
          },
        },
      ],
    }
  }, [])

  const SliderComponent = () => {
    const leftSliderData = [
      {
        controlImage:
          "//res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/spiralyze2024website/_comparison_slidernoyesno_3.webp",
        variantImage:
          "//res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/spiralyze2024website/_comparison_slidernoyesno_2.webp",
        comparisonCaption:
          "Sched.com tested moving its Contact Sales form to the left. It won.",
      },
    ]

    return (
      <>
        {leftSliderData.map((item, index) => {
          return (
            <div className="slide-item-v2" key={index}>
              <div className="react-image-compare-slider showlabels">
                <ReactCompareImage
                  handle={<CompareSliderIcon />}
                  handleSize={64}
                  sliderLineColor={"#1854F9"}
                  sliderLineWidth={1}
                  sliderPositionPercentage={0.73}
                  leftImage={item.controlImage}
                  rightImage={item.variantImage}
                />
              </div>
              <div className="comparison-caption">
                {ReactHtmlParser(item.comparisonCaption)}
              </div>
            </div>
          )
        })}
      </>
    )
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            topSliderRef.current.slickNext()
            bottomSliderRef.current.slickPrev()
          } else {
            // entry.target.style.maxWidth = "0%"
          }
        })
      },
      { threshold: 0.5 }
    )
    const elements = document.querySelectorAll(
      ".full-vw-section-wrapper.image-continous-slider"
    )
    elements.forEach(element => {
      observer.observe(element)
    })
    return () => {
      elements.forEach(element => {
        observer.unobserve(element)
      })
    }
  }, [isSSR])

  return (
    <div>
      <HeaderV2 version="v2" />
      <Hero
        version="v2"
        className="greeneclipse"
        breadcrumb={[
          { name: "Home", url: "/" },
          {
            name: "How <span class='hidemobile'>it Works</span>",
            url: "/how-it-works/",
          },
          { name: "Prediction Engine", url: null },
        ]}
        // heroVideo={`
        // res.cloudinary.com/spiralyze/video/upload/spiralyze2022/VIDEOS/latestanimationhero.mp4`}
        subHeading={null}
        heading="Get 3X more wins by <br class='hidedesktop hidetablet hidemobile'/> adapting <br class='hideipad'/> <span>proven winners.</span>"
        description="<p>The prediction engine harnesses<br class='hidedesktop hidetablet hideipad hidemobile'/> the collective wisdom of A/B tests<br class='hidedesktop hidetablet hideipad hidemobile'/> across 78,000 websites. It<br class='hidedesktop hidetablet hideipad hidemobile'/> scrapes tests, <br class='hidedesktop hidetablet hideipad'/> tracks results, and <br class='hidedesktop hidetablet hideipad hidemobile'/> determines winning patterns for<br class='hidedesktop hidetablet hideipad hidemobile'/> your site.</p>"
        tags={["Scraper", "Clustering", "Predictions", "Results"]}
        heroImage="interior_hero.webp"
        sourceFolder="spiralyze2024website"
        cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/v1710252838/spzinternal-website"
      />
      <ClientLogos version="v2 gray-bg" />
      <LeftRightImageText
        mainHeading="A/B test data scraped from 78,000 sites."
        subHeading="Scraper"
        sectionDescription="<p>The prediction engine finds, scrapes, and tracks outcomes for every A/B test it finds running on the web.<br class='hidedesktop hideipad hidemobile'/> It captures 8,000+ new tests every month.</p>"
        imageFolder="how_it_works"
        sectionImage="howitworksimage1.webp"
        reverse={true}
        innerClasses="pd-112"
        id="scraper"
        imageComponent={
          <>
            <SliderComponent />
          </>
        }
      >
        <Button
          version={"v2"}
          href="/scraper/"
          icon={<ArrowIcon />}
          type={`cta`}
        >
          Learn More
        </Button>
      </LeftRightImageText>
      <div className="gray-bg pd-112" id="clustering">
        <Container>
          <HeadSubheadHorizontal
            className="vertical-360"
            subHeading="Clustering"
            description={`<p>Individual tests are clustered together into similar patterns. When a pattern wins frequently, it is more likely to win on your site.</p>`}
          >
            Analyzing data to find repeat winning patterns.
          </HeadSubheadHorizontal>
        </Container>
        <div className="full-vw-section-wrapper image-continous-slider">
          <Slider
            className="multiple-tile-image-slider topone"
            {...settings}
            ref={topSliderRef}
          >
            {clusteringImages?.slice(0, 7).map((slide, index) => (
              <div className="image-tile" key={index}>
                <Image
                  sourceFolder={"spiralyze2024website"}
                  lazyLoad={"true"}
                  fallBackImage={`slider-item-desktop.webp`}
                  imgName={slide.image}
                  cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
                />
                <div
                  className="image-tile-footer"
                  style={{
                    backgroundColor: `rgb(${slide.color}, 0.2)`,
                  }}
                >
                  <p>{slide.name}</p>
                  {slide.uplift && (
                    <div
                      className="uplift"
                      style={{ backgroundColor: `rgb(${slide.color})` }}
                    >
                      {slide.icon}
                      <span>{slide.uplift}</span>
                    </div>
                  )}
                  {slide.icon && <div className="">{slide.icon}</div>}
                </div>
              </div>
            ))}
          </Slider>

          <Slider
            ref={bottomSliderRef}
            className="multiple-tile-image-slider"
            // style={{
            //   marginLeft: "-100px",
            // }}
            {...settings2}
          >
            {clusteringImages?.slice(7, 20).map((slide, index) => (
              <div className="image-tile" key={index}>
                <Image
                  sourceFolder={"spiralyze2024website"}
                  lazyLoad={"true"}
                  fallBackImage={`screenshot01_1.webp`}
                  imgName={slide.image}
                  cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
                />
                <div
                  className="image-tile-footer"
                  style={{
                    backgroundColor: `rgb(${slide.color}, 0.2)`,
                  }}
                >
                  <p>{slide.name}</p>
                  {slide.uplift && (
                    <div
                      className="uplift"
                      style={{ backgroundColor: `rgb(${slide.color})` }}
                    >
                      {slide.icon}
                      <span>{slide.uplift}</span>
                    </div>
                  )}
                  {slide.icon && <div className="">{slide.icon}</div>}
                </div>
              </div>
            ))}
          </Slider>
          <p>
            The scraper detected the <i>Form on the Left</i> pattern won 45
            times,
            <br className="hidemobile" /> did nothing 29 times, and lost 43
            times.
          </p>
        </div>
      </div>

      <LeftRightImageText
        mainHeading="Custom predictions for your website."
        subHeading="Predictions"
        sectionDescription="<p>Predictions are customized for your website based on your industry, goal-type, and visitor type. Using an affinity model we overweight data from websites most like yours to see what will get your visitors to convert.</p><p>Know how likely a test is to win for you, the average lift when it wins, and how it should be prioritized.</p>"
        innerClasses="reverse pd-112"
        id="predictions"
        imageComponent={
          <>
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"true"}
              fallBackImage={`image-in-block_2.webp`}
              imgName={`image-in-block_2.webp`}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
            <p>
              {" "}
              For a SaaS client, <i>Form on the Left</i> has a 37% chance of
              winning and an
              <br className="hidemobile" /> average lift of 16%.
            </p>
          </>
        }
      >
        <Button version={"v2"} href="/ai/" icon={<ArrowIcon />} type={`cta`}>
          Learn More
        </Button>
      </LeftRightImageText>

      <div className="gray-bg pd-112" id="results">
        <Container>
          <HeadSubheadHorizontal
            className="vertical-1024"
            subHeading="Results"
            description={`<p>Running proven winning tests with Predictive CRO yields a 34% win rate, 3X the industry average of 11%. Clients get faster results, averaging a first win 25 days after kickoff.</p>`}
          >
            {`Get faster results and <br class='hidedesktop hidetablet hideipad' />3X more <br class='hidedesktop hidemobile' /> winning tests.`}
          </HeadSubheadHorizontal>
          <div className="circle-bars">
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"true"}
              fallBackImage={`desktopyes_tabletno_mobileno_invertedno_2.webp`}
              // imgName={`barsdesktop.png`}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
              imgName={[
                {
                  imageName: "desktopyes_tabletno_mobileno_invertedno_2.webp",
                  minBreakpoint: "1025px",
                },
                {
                  imageName: "desktopyes_tabletno_mobileno_invertedno_3.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "desktopno_tabletno_mobileyes_invertedno_1.webp",
                  minBreakpoint: "320px",
                },
              ]}
            />
          </div>
          <div className="circle-bar-ctas">
            <Button href={`/get-demo/`} version={`v2`} type={`cta`} icon={null}>
              Learn More
            </Button>
            <Button href={`/pricing/`} version={`v2`} icon={null}>
              Pricing
            </Button>
          </div>
        </Container>
      </div>

      <TopPredictions version="v2" />
      <ClientResults version="v2" />
    </div>
  )
}
